.flat-card {
    .row-table {
        display: table;
        align-items: center;
        table-layout: fixed;
        height: 100%;
        width: 100%;
        margin: 0;

        &:first-child {
            border-bottom: 1px solid #ddd;
        }

        .br {
            border-right: 1px solid #ccc;
        }

        i {
            font-size: 28px;
        }

        span {
            text-transform: uppercase;
            font-size: 12px;
        }

        h5 {
            display: block;
            margin-bottom: 0.3em;
            margin-right: 0;
        }

        > [class*=col-] {
            display: table-cell;
            float: none;
            table-layout: fixed;
            vertical-align: middle;

            .row {
                display: flex;
                align-items: center;
            }
        }
    }

    &[class*="widget-"] {
        .row-table {
            display: flex;
        }
    }
}

.widget-primary-card.flat-card {
    border-top: none;
    background-color: $primary-color;
    color: #fff;

    h4,
    h6 {
        color: #fff;
    }

    .row-table {
        &:first-child {
            border-bottom: none;
        }

        > [class*=col-] {
            &:first-child {
                background-color: darken($primary-color, 5%);
                text-align: center;
            }
            display: inline-block;
            vertical-align: middle;

            .row {
                display: flex;
                align-items: center;
            }
        }
    }
}

.widget-purple-card.flat-card {
    @extend .widget-primary-card;
    background-color: $purple-color;

    .row-table {
        &:first-child {
            border-bottom: none;
        }

        > [class*=col-] {
            &:first-child {
                background-color: darken($purple-color, 5%);
            }
        }
    }
}

.table-card {
    .card-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        .table {
            > thead > tr > th {
                border-top: 0;
            }

            tr {
                td,
                th {
                    &:first-child {
                        padding-left: 25px;
                    }

                    &:last-child {
                        padding-right: 25px;
                    }
                }
            }

            &.without-header {
                tr {
                    &:first-child {
                        td {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }
}

.stastic-slider-full-card {
    .carousel-item {
        transition: transform 12s linear,-webkit-transform 12s linear;
    }
}

.statstic-data-card {
    .active.carousel-item-right,
    .carousel-item-next:not(.carousel-item-left) {
        transform: translateY(100%);
    }

    .active.carousel-item-left,
    .carousel-item-prev:not(.carousel-item-right) {
        transform: translateY(-100%);
    }

    .card-body {
        border-top: 2px solid transparent;

        &.level-down {
            border-color: $danger-color;
        }

        &.level-up {
            border-color: $success-color;
        }
    }
}

.social-widget-card {
    border-top: none;
    color: #fff;

    &:hover i {
        opacity: 1;
        transform: scale(1.1);
    }

    i {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 48px;
        opacity: 0.4;
        transition: all ease-in 0.3s;
    }
}

.comp-card {
    i {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        padding: 17px 0;
        font-size: 18px;
    }
}

.prod-p-card {
    i {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding: 11px 0;
        font-size: 24px;
    }
}

.proj-progress-card {
    .progress {
        height: 6px;
        overflow: visible;
        margin-bottom: 10px;
    }
    @media only screen and (max-width: 992px) {
        h6 {
            margin-top: 15px;
        }

        .progress {
            margin-bottom: 20px;
        }
    }
}

.proj-t-card {
    position: relative;
    overflow: hidden;

    .pt-badge {
        margin-bottom: 0;
        display: inline-block;
        padding: 60px 50px 20px 20px;
        border-radius: 50%;
        position: absolute;
        top: -45px;
        right: -35px;
        transition: all 0.3s ease-in-out;
    }
}

.ticket-card {
    .lbl-card {
        border-radius: 50px;
        padding: 5px 15px;
        display: inline-block;
    }
}

.analytic-card {
    .analytic-icon {
        width: 50px;
        height: 50px;
        text-align: center;
        padding: 17px 0;
        border-radius: 50%;
        background-color: #fff;
    }
}

.widget-profile-card-1 {
    position: relative;
    margin-bottom: 50px;

    .middle-user {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        bottom: -60px;

        img {
            width: 115px;
            border-radius: 50%;
        }
    }

    ~ .card-footer [class*=col-] {
        border-right: 1px solid #fff;

        &:last-child {
            border: none;
        }
    }
    @media only screen and (max-width:767px) {
        ~ .card-footer [class*=col-]:nth-child(2) {
            border: 0;
        }

        ~ .card-footer [class*=col-]:last-child {
            margin-top: 10px;
        }
    }
}

.widget-profile-card-2 {
    img.user-img {
        width: 115px;
    }

    a {
        color: #fff;
        transition: all ease-in 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }
}

.widget-profile-card-3 {
    background-image: url("~/assets/images/widget/slider5.jpg");
    background-size: cover;
    padding: 50px 0;
    text-align: center;

    img {
        width: 120px;
        border-radius: 50%;
    }
}

.social-res-card {
    .progress {
        height: 6px;
    }
}

.product-progress-card {
    .progress {
        height: 6px;
    }

    .pp-cont {
        padding-left: 15px;
        padding-right: 15px;
        position: relative;

        &:after {
            content: "";
            background: #d2d2d2;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: -15px;
        }
    }

    .pp-main > div:first-child > .pp-cont:after {
        display: none;
    }
}

.profile-card {
    position: relative;
    min-height: 410px;
    overflow: hidden;

    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .card-body {
        text-align: center;
        color: #fff;
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.72));

        h3 {
            font-weight: 600;
        }
    }

    ~ .card-footer {
        [class*="col-"] {
            padding: 10px;
            border-right: 1px solid rgba(255, 255, 255, 0.57);

            h4 {
                font-size: 18px;
                font-weight: 600;
            }

            &:last-child {
                border: none;
            }

            span {
                text-transform: uppercase;
            }
        }
        @media only screen and (max-width:767px) {
            [class*="col-"]:nth-child(2) {
                border: none;
            }
        }
    }
}

.blur-user-card {
    color: #fff;
    text-align: center;
    padding-top: 45px;
    background-image: url("~/assets/images/widget/blur-bg.png");
    background-size: cover;

    h3 {
        margin-top: 10px;
        font-size: 30px;
        font-weight: 600;
    }

    p {
        font-size: 18px;
        font-weight: 300;
    }

    .blur-footer {
        [class*="col-"] {
            padding: 1em;
            border-right: 1px solid #fff;
            margin-top: 30px;
            margin-bottom: 30px;

            &:last-child {
                border-right: none;
            }
        }
        @media only screen and (max-width:767px) {
            [class*="col-"]:nth-child(2) {
                border: 0;
            }

            [class*="col-"]:last-child {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

.weather-bg-card {
    position: relative;

    img {
        width: 100%;
    }

    .card-body {
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
    }

    h5 {
        color: #fff;
    }

    .card-body.alt {
        position: absolute;
        top: 0;

        span {
            color: #fff;
        }
    }

    .weather-temp {
        font-size: 70px;
        color: #fff;
    }

    ~ .card-footer {
        border-top: none;
        @media only screen and (max-width:575px) {
            [class*="col-"] {
                margin-bottom: 10px;
            }
        }
    }
}

.new-cust-card {
    img {
        width: 40px;
    }

    h6 {
        margin-bottom: 0;
    }

    .align-middle {
        position: relative;

        .status {
            position: absolute;
            right: 0;
            top: 19px;
            font-size: 13px;

            &.active {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $success-color;
            }
        }
    }
}

.latest-update-card {
    .card-body {
        padding-top: 0;

        .latest-update-box {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                background: #ebebeb;
                height: 100%;
                width: 3px;
                top: 0;
                left: 11px;
                z-index: 1;
            }

            .update-meta {
                z-index: 2;

                .update-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    text-align: center;
                    padding: 7px 8px;
                    font-size: 16px;
                    color: #fff;

                    &.bg-c-red {
                        box-shadow: 0 0 0 4px transparentize($danger-color, 0.5),;
                    }

                    &.bg-c-blue {
                        box-shadow: 0 0 0 4px transparentize($primary-color, 0.5);
                    }

                    &.bg-c-green {
                        box-shadow: 0 0 0 4px transparentize($success-color, 0.5);
                    }

                    &.bg-c-yellow {
                        box-shadow: 0 0 0 4px transparentize($warning-color, 0.5);
                    }

                    &.ring {
                        border-width: 3px;
                        width: 15px;
                        height: 15px;
                        padding: 0;
                        display: block;
                        background: #fff;
                    }
                }

                i.update-icon {
                    margin-left: 4px;
                    margin-right: 4px;
                }

                img.update-icon {
                    padding: 0;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}

.latest-update-card {
    &.update-card {
        .card-body {
            .latest-update-box {
                &:after {
                    left: 19px;
                }
            }
        }
    }
}

.testimonial-card {
    .progress {
        height: 5px;
    }

    .review-block {
        .cust-img {
            width: 40px;
            height: 40px;
        }

        > div {
            padding-top: 15px;
            padding-bottom: 9px;
            margin-left: 0;
            margin-right: 0;
            padding-left: 5px;
            padding-right: 5px;

            &:hover {
                background-color: transparentize($primary-color, 0.9);
            }
        }
    }
}

.widget-chat-box {
    .card-header, .card-body {
        i {
            font-size: 17px;
            color: $inverse-color;
            position: relative;
        }

        .pop-up {
            &:after {
                content: '';
                position: absolute;
                background-color: $danger-color;
                height: 8px;
                width: 8px;
                border-radius: 50px;
                right: -3px;
            }
        }
    }

    .send-chat {
        $chat-color: #eae9e9;
        position: relative;
        background: lighten($primary-color, 30%);
        padding: 7px;
        border-radius: 0 10px 10px 10px;
        font-size: 13px;
        margin-bottom: 30px;

        &:before {
            content: '';
            position: absolute;
            height: 12px;
            width: 12px;
            border: 6px solid transparent;
            border-top: 6px solid lighten($primary-color, 30%);
            border-right: 6px solid lighten($primary-color, 30%);
            left: -12px;
            top: 0;
        }

        .time {
            position: absolute;
            bottom: -20px;
            left: 0;
        }
    }

    .receive-chat {
        @extend .send-chat;
        background-color: $primary-color;
        color: #fff;
        border-radius: 10px;

        &:before {
            display: none;
        }

        .time {
            color: #666;
        }
    }

    .rc-10 {
        margin-bottom: 10px;
    }
}

.chat-card {
    .msg {
        margin-bottom: 5px;
        display: inline-block;
        padding: 10px;
        position: relative;

        img {
            width: 60px;
            border-radius: 5px;
            margin-bottom: 5px;
            margin-top: 5px;
            margin-right: 10px;
        }
    }

    .received-chat {
        .msg {
            background: lighten($primary-color, 30%);
            border-radius: 0 5px 5px 5px;

            &:after {
                content: "";
                position: absolute;
                left: -7px;
                top: -7px;
                transform: rotate(45deg);
                border: 7px solid transparent;
                border-bottom-color: lighten($primary-color, 30%);
            }
        }
    }

    .send-chat {
        text-align: right;

        .msg {
            background: $primary-color;
            color: #fff;
            border-radius: 5px 0 5px 5px;

            &:after {
                content: "";
                position: absolute;
                right: -7px;
                top: -7px;
                transform: rotate(45deg);
                border: 7px solid transparent;
                border-right-color: $primary-color;
            }
        }
    }
}

.nav {
    position: relative;
    z-index: 1;
}

.feed-card {
    h6 {
        margin-top: 7px;
        font-size: 14px;
    }

    .feed-icon {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 8px 9px;
    }
}

.mrr-card {
    position: relative;

    .bottom-chart {
        bottom: 0;
    }
}
