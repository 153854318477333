/**======================================================================
=========================================================================
Template Name: Next Admin Template
Author: Codedthemes
File: style.css
=========================================================================
=========================================================================
    == Table of Contents==
    - Generic classes
        -	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
    - Theme Elements
        -	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
                -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
                -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
        - Forms
                -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
    - Pages
        -   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
                -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */


// Theme Font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugin/perfect-scrollbar";

// main framework
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

// bootstrap
@import "bootstrap/scss/bootstrap";


// icon
@import "themes/font/datta-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";

// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/layouts/menu-ng";

// Dashboard Widgets
@import "themes/dashboard/widget";

// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/user";

// custom
@import "themes/custom";
@import "@ng-select/ng-select/themes/default.theme.css";

// regular style toast
@import 'ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
@import 'ngx-toastr/toastr-bs4-alert';

// if you'd like to use it without importing all of bootstrap it requires
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr-bs4-alert';

// mixins
@import 'settings/mixin';

// spinner
@import 'spinner/spinner';

.toast-success{
    background-image: none !important;
    background-color: green;
}

.toast-warning{
    background-image: none !important;
    background-color: orange;
}

.toast-error{
    background-image: none !important;
    background-color: red;
}

.toast-info{
    background-image: none !important;
    background-color: blue;
}

.required:after {
    content:" *";
    color: red;
    font-weight: bold;
}

/* Base element for hover */
.umsHint {
    // position: relative;
    // display: inline-block;
    cursor: pointer;    
    padding: 1px;

    border: 1px solid gray;

    width: 20px;  /* Set equal width */
    height: 20px; /* Set equal height */
    // background-color: lightblue;
    border-radius: 50%; /* Creates a perfect circle */
    display:inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;  /* Center text within the circle */
    color: gray;

    margin-left: 5px;
    // margin-top: 2px;
}

.legacyHint {
    position: relative;
    display: inline-block;
    cursor: pointer;    
    padding: 1px;

    border: 1px solid gray;

    width: 20px;  /* Set equal width */
    height: 20px; /* Set equal height */
    // background-color: lightblue;
    border-radius: 50%; /* Creates a perfect circle */
    display:inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;  /* Center text within the circle */
    color: gray;

    margin-left: 5px;
    // margin-top: 2px;
}

/* Tooltip content hidden by default */
.legacyHint::after {
    content: attr(data-legacyHint);
    position: absolute;
    bottom: 100%; /* Position above the tooltip trigger */
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: black;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s;
    pointer-events: none; /* Avoids interfering with mouse events */
}

/* Show the tooltip on hover */
.legacyHint:hover::after {
    opacity: 0.5; /* Show the tooltip */
}