// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$blue-light: #F0F4FF;
$boarder-coloration: #D9E1FC;
$prelude-blue: #1C55FB;

$header-height: 60px;
// $Menu-width: 250px;
$Menu-width: 270px;
$Menu-collapsed-width: 70px;

// color list for build layouts
$primary-color: $prelude-blue;
$warning-color: #ffa21d;
$danger-color: #ff5252;
$success-color: #0069d9;
$purple-color: #7759de;
$info-color: #00ACC1;
$secondary-color: #748892;
$default-color: #d6d6d6;
$light-color: #ECEFF1;
$dark-color: #263238;
$inverse-color: $dark-color;
$theme-border: #e2e5e8;
$jagged-ice-color: $blue-light;
$ebony-color: #303135;

// header
$header-dark-background: #101b33;
$header-dark-text-color: #000;
$header-light-background: #fff;
$header-light-text-color: #101b33;

// Menu
$menu-dark-background: #fff;
// $menu-dark-text-color: #b5bdca;
$menu-dark-text-color: #000000;

$menu-light-background: #fff;
$menu-light-text-color: #535763;
$menu-active-color: $primary-color;

$dark-layout: darken(#101b33,8%);
$dark-layout-font: #adb7be;


// Menu icon
$menu-icon-color: #00a064, #ff5252, #01a9ac ,#7759de , #00ACC1, $warning-color;
// Header background
$color-header-name: blue, red, purple, info, dark;
$color-header-color: #ffffff, #ff5252, #7759de ,#00ACC1,#101b33;
// Menu background
$color-menu-name: blue, red, purple, info, dark;
$color-menu-color: #00a064, #ff5252, #7759de ,#00ACC1,#101b33;

// Menu background gradient
$color-menu-gradient-name: blue, red, purple, info, dark;
$color-menu-gradient-color: linear-gradient(to bottom, #7759de 0%, #00a064 100%),
linear-gradient(to bottom, rgb(164, 69, 178) 0%, rgb(212, 24, 114) 52%, rgb(255, 0, 102) 100%),
linear-gradient(to top, rgb(178, 117, 240) 0%, rgb(117, 117, 240) 100%) ,
linear-gradient(to bottom, rgb(1, 169, 172) 0%, rgb(1, 219, 223) 100%),
linear-gradient(to bottom, rgb(36, 46, 62) 0%, rgb(58, 76, 103) 100%);

// Active background color
$color-active-name: blue, red, purple, info, dark;
$color-active-color: #00a064, #ff5252, #7759de ,#00ACC1,$dark-color;
 // Menu title color
$color-title-name: blue, red, purple, info, dark;
$color-title-color: #00a064, #ff5252, #7759de ,#00ACC1,$dark-color;

$theme-font-family: 'Mada', sans-serif;
$theme-font-size: 14px;
$theme-background: #f4f5fa;
$theme-font-color: #535763;
// $theme-heading-color: #111;
$theme-heading-color: #060606;

$color-name: c-blue,c-red,c-green,c-yellow,c-purple;
$color-color: $primary-color,$danger-color,$success-color ,$warning-color ,$purple-color;
$color-bt-name: primary,danger,success,warning,info,purple;
$color-bt-color: $primary-color,$danger-color,$success-color ,$warning-color ,$info-color,$purple-color;

// form-control variables
$form-bg: #f0f3f6;

// social color variables
$social-name: facebook,twitter,dribbble,pinterest,youtube,googleplus,instagram,viber,behance,dropbox,linkedin;
$social-color: #3C5A99,#42C0FB,#EC4A89,#BF2131,#E0291D,#C73E2E,#AA7C62,#7B519D,#0057ff,#3380FF,#0077B5;

// =====================================================================
// ==================   Bootstrap Extra Variable  ======================
// =====================================================================

// Color contrast
$yiq-contrasted-threshold: 200;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8%;
$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// card block css
$card-shadow: 0 1px 3px 0 rgba(54, 80, 138, 0.30);
$card-header-border:0px solid #e2e5e8;
