/* arabic */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 400;
  src: local('Mada Regular'), local('Mada-Regular'), url(~/assets/fonts/mada/7Auwp_0qnzeSfTfGLRrX.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 400;
  src: local('Mada Regular'), local('Mada-Regular'), url(~/assets/fonts/mada/7Auwp_0qnzeSfTLGLQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 500;
  src: local('Mada Medium'), local('Mada-Medium'), url(~/assets/fonts/mada/7Au_p_0qnzeSdcHlOCD2z3wM.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 500;
  src: local('Mada Medium'), local('Mada-Medium'), url(~/assets/fonts/mada/7Au_p_0qnzeSdcHlOCX2zw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 600;
  src: local('Mada SemiBold'), local('Mada-SemiBold'), url(~/assets/fonts/mada/7Au_p_0qnzeSde3iOCD2z3wM.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-weight: 600;
  src: local('Mada SemiBold'), local('Mada-SemiBold'), url(~/assets/fonts/mada/7Au_p_0qnzeSde3iOCX2zw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
